exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-data-protection-app-jsx": () => import("./../../../src/pages/data_protection_app.jsx" /* webpackChunkName: "component---src-pages-data-protection-app-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/blog-template.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-faq-template-jsx": () => import("./../../../src/templates/faq-template.jsx" /* webpackChunkName: "component---src-templates-faq-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */)
}

