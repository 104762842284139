/**
 * https://www.gatsbyjs.com/plugins/gatsby-theme-material-ui/
 */

import { createTheme } from '@mui/material';
import { green, red } from '@mui/material/colors';

const kkiBreakpointValues = {
  values: {
    xs: 0,
    sm: 720,
    md: 960,
    lg: 1440,
    xl: 1920,
  },
};

export const colors = {
  kkimargenta: '#d82772',
  kkigreen: '#27c299',
  grey: {
    lighter: '#f6f6f6',
    light: '#474747',
    main: '#1e1e1e',
    dark: '#121212',
  },
  white: '#ffffff',
  black: '#000000',
};

export const typo = {
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    bolder: 900,
  },
};

// createBreakpoints() is deprecated in MUI v5, use intermediate theme as suggested here https://github.com/mui-org/material-ui/issues/28330#issuecomment-919852856

// eslint-disable-next-line import/no-mutable-exports
let themeDark = createTheme({
  breakpoints: kkiBreakpointValues,
});

themeDark = createTheme({
  breakpoints: kkiBreakpointValues,
  palette: {
    primary: {
      light: colors.grey.light,
      main: colors.grey.main,
      dark: '#000000',
    },
    secondary: {
      light: '#ff63a0',
      main: colors.kkimargenta,
      dark: '#a10047',
    },
    grey: {
      100: colors.grey.lighter,
      200: '#e3e3e3',
      400: '#616161',
      500: colors.grey.light,
      800: colors.grey.main,
      900: colors.grey.dark,
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      Light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
    common: {
      white: colors.white,
      black: colors.black,
    },
    text: {
      primary: colors.white,
      secondary: colors.black,
    },
    link: {
      primary: colors.kkimargenta,
      secondary: colors.kkigreen,
    },
    green: {
      main: colors.kkigreen,
    },
    background: {
      default: colors.grey.main,
      paper: colors.white,
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightLight: typo.weights.light,
    fontWeightRegular: typo.weights.normal,
    fontWeightMedium: typo.weights.medium,
    fontWeightBold: typo.weights.bold,
    fontWeightBolder: typo.weights.bolder,
    fontFamilySecondary: "'Flicker', sans-serif",
    h1: {
      // Roboto-Bold
      fontSize: 30,
      fontWeight: typo.weights.bold,
      textTransform: 'uppercase',
      lineHeight: 1.23,
      '& .MuiTypography-gutterBottom': {
        marginBottom: '140px',
      },
    },
    h2: {
      // Roboto-Bold
      fontSize: 24,
      fontWeight: typo.weights.bold,
      lineHeight: 1.25,
    },
    h3: {
      // Roboto-Bold
      fontSize: 22,
      fontWeight: typo.weights.bold,
      lineHeight: 1.68,
    },
    caption: {
      // Roboto-Bold
      fontSize: 15,
      fontWeight: typo.weights.bold,
      color: colors.grey.light,
      letterSpacing: -0.07,
    },
    overline: {
      // Roboto-Bold
      color: colors.grey.light,
      fontSize: 15,
      fontWeight: typo.weights.bold,
      textTransform: 'uppercase',
      letterSpacing: -0.07,
    },
    body1: {
      // Roboto-Medium
      fontWeight: typo.weights.medium,
      letterSpacing: -0.08,
      lineHeight: 1.5,
      fontSize: '1rem',
      [themeDark.breakpoints.up('sm')]: {
        fontSize: '1.125rem',
      },
    },
    body2: {
      // Roboto-Regular
      fontWeight: typo.weights.normal,
      letterSpacing: -0.08,
      lineHeight: 1.5,
      fontSize: '1rem',
      [themeDark.breakpoints.up('sm')]: {
        fontSize: '1.125rem',
      },
    },
    button: {
      // Roboto-Medium
      fontSize: 14,
      fontWeight: typo.weights.medium,
      textTransform: 'uppercase',
    },
  },
});

export const themeLight = createTheme({
  ...themeDark,
  palette: {
    ...themeDark.palette,
    text: {
      primary: colors.black,
      secondary: colors.white,
    },
    background: {
      default: colors.white,
      paper: colors.grey.main,
    },
  },
});

export default themeDark;
